<template>
  <div class="logo">
    <div class="logo_font">欢迎登录</div>
    <div class="logo_font bottom_font">黑蜻蜓充电宝(设备方)</div>
    <div class="inputbox">
      <input
        class="inputinbox"
        type="number"
        v-model="formData.mobile"
        @blur="phonejc"
        placeholder="请输入手机号码"
      />
    </div>
    <div class="inputbox" v-if="logotype">
      <input
        class="inputinbox"
        type="password"
        v-model="formData.password"
        placeholder="请输入密码"
      />
    </div>
    <div class="inputbox" v-if="!logotype">
      <input
        class="input_left"
        placeholder="请输入验证码"
        v-model="formData.yzm"
      />
      <div class="input_right">
        <div
          class="yzm_send"
          @click="sendyzm"
          v-if="yzmtype == 1 || yzmtype == 3"
        >
          {{ yzmtype == 1 ? "获取验证码" : "重新获取" }}
        </div>
        <div class="yzm_send" v-if="yzmtype == 2">{{ num }}s</div>
      </div>
    </div>
    <!-- <div class="remember">
      <div class="rem_left" @click="changerem">
        <div class="rem_top" v-if="!rembermer"></div>
        <div class="rem_top2" v-if="rembermer">
          <img class="intop" src="../assets/img/rember.png" />
        </div>
        <div class="rem_bottom">记住密码</div>
      </div>
      <div class="rem_right" @click="routers('/forget')">忘记密码</div>
    </div> -->
    <div class="login_btn" @click="submit">登录</div>
    <!-- <div class="login_btn" @click="wxsubmit">微信登录</div> -->
    <!-- <div class="change_font" @click="changetype">
      {{ logotype ? "验证码登录" : "密码登录" }}
    </div> -->
  </div>
</template>
<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      logotype: true,
      rembermer: true,
      yzmtype: 1,
      num: 60,
      timer: "",
      formData: {
        mobile: "",
      },
    };
  },
  created() {
    let code = this.getUrlKey("code");
    console.log(code, "code");
    if (code) {
      this.$http.weChat({ code }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem("token", res.token);
          this.$router.push("/");
        } else {
          Notify({ type: "primary", message: res.msg });
        }
      });
    }
  },
  methods: {
    //改变登录方式
    changetype() {
      this.logotype = !this.logotype;
    },
    //记住密码
    changerem() {
      this.rembermer = !this.rembermer;
    },
    //发送验证码
    sendyzm() {
      console.log(this.formData.mobile);
      if (this.formData.mobile) {
        this.$http.msgSend2({ mobile: this.formData.mobile, msgType:14}).then((res) => {
          console.log(res);
          if (res.code == 200) {
            Notify({ type: "primary", message: res.msg });
          } else {
            Notify({ type: "primary", message: res.msg });
          }
        });
        this.yzmtype = 2;
        this.timer = setInterval(() => {
          this.num = this.num - 1;
          if (this.num <= 0) {
            clearInterval(this.timer);
            this.num = 60;
            this.yzmtype = 3;
          }
        }, 1000);
      } else {
        Notify({ type: "primary", message: "请先输入手机号" });
      }
    },
    //检测手机号
    phonejc() {
      console.log(this.formData);
      if (/^1[3-9]\d{9}$/.test(this.formData.mobile)) {
        console.log("jinlal");
      } else {
        this.formData.mobile = "";
        Notify({ type: "primary", message: "手机号格式错误" });
      }
    },
    //路由
    routers(path) {
      console.log("path", path);
      this.$router.push(path);
    },
    //登录
    submit() {
      console.log(this.formData);
      if (!this.logotype) {
        console.log("验证码登录", this.formData.mobile && this.formData.yzm);
        if (this.formData.mobile && this.formData.yzm) {
          if (this.$http.beishiTeMobileArr.indexOf(this.formData.mobile) != -1){
            this.$http.changeUrl2()
          }
          this.$http
            .loginmobile2({
              code: this.formData.yzm,
              mobile: this.formData.mobile,
            })
            .then((res) => {
              console.log(res);
              if (res.code == 200) {
                localStorage.setItem("token", res.token);
                localStorage.setItem("loginMobile", this.formData.mobile);
                // this.$router.push("/saoma");
                window.location.href="https://gzh.heiqingting.net/saoma"
               
              } else {
                Notify({ type: "primary", message: res.msg });
              }
            });
        } else {
          Notify({ type: "primary", message: "手机号或者验证码未输入" });
        }
      } else {
        if (this.formData.mobile && this.formData.password) {
          console.log('进来了',this.formData)
          if (this.$http.beishiTeMobileArr.indexOf(this.formData.mobile) != -1){
            console.log('改变Url')
            this.$http.changeUrl2()

          }
          this.$http
            .loginmobile2({
              password: this.formData.password,
              mobile: this.formData.mobile,
            })
            .then((res) => {
              console.log(res);
             if (res.code == 200) {
                localStorage.setItem("loginMobile", this.formData.mobile);
                localStorage.setItem("token", res.token);
                // this.$router.push("/saoma");
                window.location.href="https://gzh.heiqingting.net/saoma"
              } else {
                Notify({ type: "primary", message: res.msg });
              }
            });
        } else {
          Notify({ type: "primary", message: "手机号或者密码未输入" });
        }
      }
    },
    //微信登录
    wxsubmit() {
      var url = encodeURIComponent(window.location.href);
      console.log(url);
      var getCodeUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5dd6d10a5324932b&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
      window.location.href = getCodeUrl;
      console.log(encodeURIComponent(window.location.href));
    },
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  overflow-y: auto;
  padding: 0 30px;
  padding-top: 84px;
  box-sizing: border-box;
  .bottom_font {
    margin-bottom: 24px !important;
  }
  .logo_font {
    width: 315px;
    margin: 0 auto;
    margin-bottom: 16px;
    font-weight: 500;
    color: #333333;
    font-size: 24px;
  }
  .inputbox {
    width: 315px;
    margin: 0 auto;
    margin-bottom: 16px;
    height: 48px;

    background: rgba(0, 112, 251, 0.05);
    border-radius: 4px;
    display: flex;
    .inputinbox {
      width: 100%;
      height: 100%;

      background: rgba(0, 112, 251, 0.05);
      font-size: 16px;
      padding-left: 16px;
      box-sizing: border-box;
    }
  }
  .login_btn {
    margin: 0 auto;
    margin-top: 32px;
    width: 315px;
    height: 47px;
    background: #1890ff;
    border-radius: 24px;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    line-height: 47px;
    text-align: center;
  }
  .change_font {
    width: 315px;
    text-align: center;
    margin: 0 auto;
    margin-top: 32px;
    font-weight: 500;
    color: #0070fb;
    font-size: 14px;
  }
  .input_left {
    width: 191px;
    height: 100%;
    font-size: 16px;
    padding-left: 16px;
    box-sizing: border-box;
    background: rgba(0, 112, 251, 0.05);
  }
  .input_right {
    height: 100%;
    flex: 1;
    background: rgba(0, 112, 251, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    .yzm_send {
      width: 100%;
      border-left: 1px solid #bac4c9;
      font-weight: 500;
      color: #333333;
      font-size: 12px;
      text-align: center;
    }
  }
  .remember {
    width: 315px;
    margin: 0 auto;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    align-items: center;
    .rem_left {
      display: flex;
      align-items: center;
      height: 15px;
      width: 100px;
      .rem_top {
        margin-right: 8px;
        width: 14px;
        height: 14px;
        border: 1px solid #ccc;
        border-radius: 50%;
      }
      .rem_top2 {
        margin-right: 8px;
        width: 14px;
        height: 14px;
        border: 1px solid transparent;
        .intop {
          width: 100%;
          height: 100%;
        }
      }
      .rem_bottom {
        margin-top: 2px;
      }
    }
  }
}
</style>